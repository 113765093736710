import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_avatar = _resolveComponent("n-avatar")!
  const _component_n_h2 = _resolveComponent("n-h2")!
  const _component_n_space = _resolveComponent("n-space")!
  const _component_n_a = _resolveComponent("n-a")!
  const _component_n_divider = _resolveComponent("n-divider")!
  const _component_n_card = _resolveComponent("n-card")!
  const _component_n_grid_item = _resolveComponent("n-grid-item")!
  const _component_n_grid = _resolveComponent("n-grid")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_n_divider, null, {
      default: _withCtx(() => [
        _createVNode(_component_n_a, {
          href: "https://www.deviantart.com/ssjrzamasugod",
          target: "_blank"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_n_space, { justify: "center" }, {
              default: _withCtx(() => [
                _createVNode(_component_n_avatar, {
                  size: 48,
                  src: _ctx.avatarUrl
                }, null, 8, ["src"]),
                _createVNode(_component_n_h2, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.name), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_n_grid, {
      cols: "1 s:2 m:3 l:4 xl:5 2xl:6",
      responsive: "screen"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results, (result) => {
          return (_openBlock(), _createBlock(_component_n_grid_item, {
            key: result.title
          }, {
            default: _withCtx(() => [
              _createVNode(_component_n_card, {
                "content-style": "margin: auto;",
                hoverable: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_n_a, {
                    href: result.link,
                    target: "_blank"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        src: result.thumbL
                      }, null, 8, _hoisted_1)
                    ]),
                    _: 2
                  }, 1032, ["href"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ], 64))
}
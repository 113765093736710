
import { defineComponent } from "vue";
import { NSpace, NH1 } from "naive-ui";
import DevianArtPreview from "@/components/DevianArtPreview.vue";

export default defineComponent({
  components: {
    DevianArtPreview,
    NSpace,
    NH1,
  },
});
